@import './variable.scss';

body {
    .mat-radio-button.mat-accent .mat-radio-inner-circle,
    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
    .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
        background-color: var(--variable-color-blue);
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: var(--variable-color-blue);
    }

    .mat-radio-button {
        display: block;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        margin-bottom: 12px;
    }

    .mat-radio-outer-circle {
        border-color: #49454f;
    }

    .mat-radio-label-content {
        font-weight: 400;
        padding-left: 18px;
    }

    .mat-radio-button .mat-radio-ripple {
        display: none;
    }
}

// .mat-mdc-dialog-container {
//     .mdc-dialog__container {}
// }

.date-modal,
.flight-model,
.delete-modal,
.time-shift-modal,
.copy-event-modal,
.model-image,
.invitees-modal,
.successful-modal,
.location-modal,
.bank-details-modal,
.QR-code-modal,
.add-user-modal,
.takeover-modal,
.import-modal,
.connect-modal,
.merge-modal,
.generating-modal,
.import-flight-hotel-model,
.unsplash-model,
.event-close-modal {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 10px;
    }
}

.cdk-overlay-pane {
    &.hide-model {
        width: 0;
        height: 0;
        visibility: hidden;
    }
}

.event-close-modal {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        background-color: transparent !important;
    }
}

.square-modal {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 0;
    }
}

.hide-modal {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        display: none;
    }
}

.time-picker-modal {
    .mat-mdc-dialog-container {
        .mdc-dialog__container {
            .mat-mdc-dialog-surface {
                overflow: visible;
            }
        }
    }
}

.mat-sort-header-content {
    text-align: left !important;
}

.mat-pseudo-checkbox {
    display: none !important;
}

// .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix

.menu {
    padding: 5px;
    min-height: auto;

    .mat-mdc-menu-content:not(:empty) {
        padding: 0;

        .mat-mdc-menu-item {
            // height: 36px;
            // line-height: 36px;
            border-radius: 4px;
            font-size: 14px;
            color: var(--variable-color-black);
        }
    }
}

.mdc-menu-surface--open {
    box-shadow: var(--variable-color-drop-down-box-shadow) !important;
    border: solid 1px var(--variable-color-light-white) !important;
    background-color: var(--variable-color-white) !important;

    .mat-mdc-menu-content {
        form {
            .mat-form-field-flex {
                padding-top: 0;
            }
        }
    }

    .mat-mdc-menu-content:not(:empty) {
        padding: 0;
    }
    .mdc-list-item__primary-text {
        color: var(--variable-color-black);
    }
}

mat-drawer-content,
.mat-mdc-menu-content {
    background: var(--variable-color-white) !important;
}

mat-mdc-tooltip {
    background: var(--variable-color-black) !important;
    color: var(--variable-color-white) !important;
}

.mdc-menu-surface--open {
    box-shadow: var(--variable-color-drop-down-box-shadow);
    border: solid 1px var(--variable-color-light-white);
    background-color: var(--variable-color-white);
    padding: 0;

    &.mdc-list-item {
        padding: 15px;
    }

    .mat-mdc-menu-content:not(:empty) {
        padding: 0;

        .mat-mdc-menu-item {
            // line-height: 36px;
            min-height: 36px;
            border-radius: 4px;

            .user-details {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding: 15px 0;

                span {
                    line-height: normal;
                }
            }

            &:hover {
                background-color: var(--variable-color-light-white);
            }

            .mat-menu-submenu-icon {
                display: none;
            }
        }
    }
}

.mat-checkbox-wrapper {
    .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
        background-color: var(--variable-color-blue) !important;
        opacity: 0.16;
    }

    .mat-mdc-checkbox
        .mdc-checkbox:not(:disabled):active
        .mdc-checkbox__native-control:enabled:indeterminate
        ~ .mdc-checkbox__background {
        border-color: var(--variable-color-off-black) !important;
        background-color: var(--variable-color-blue) !important;
    }

    .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mat-mdc-checkbox
        .mdc-checkbox:hover
        .mdc-checkbox__native-control:enabled:indeterminate
        ~ .mdc-checkbox__background {
        border-color: var(--variable-color-blue) !important;
        background-color: var(--variable-color-blue);
    }

    .mat-mdc-checkbox
        .mdc-checkbox
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background {
        border-color: var(--variable-color-off-black) !important;
    }

    .mdc-checkbox
        .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background {
        border-color: var(--variable-color-black);
        opacity: 0.3;
    }

    .mat-mdc-checkbox .mat-ripple-element {
        background-color: var(--variable-color-blue) !important;
        opacity: 0.16;
    }

    .mat-mdc-checkbox {
        label {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: var(--variable-color-black);
        }

        .mdc-checkbox__ripple {
            background: transparent;
            display: none;
        }

        &.mdc-checkbox--anim-checked-unchecked {
            .mdc-form-field {
                .mdc-checkbox {
                    .mdc-checkbox__ripple {
                        background: transparent;
                        display: none;
                    }
                }
            }
        }
        &.mdc-checkbox--anim-unchecked-checked {
            .mdc-form-field {
                .mdc-checkbox {
                    .mdc-checkbox__ripple {
                        background: transparent;
                        display: none !important;
                    }
                }
            }
        }

        &.mat-mdc-checkbox-checked {
            .mdc-form-field {
                .mdc-checkbox {
                    &.mdc-checkbox--selected {
                        background: var(--variable-color-blue);
                    }

                    .mdc-checkbox__ripple {
                        background: transparent;
                        background-color: var(--variable-color-blue) !important;
                    }

                    .mdc-checkbox__background {
                        background-color: var(--variable-color-blue) !important;
                        border-color: var(--variable-color-blue) !important;
                    }
                }
            }
        }
    }
}

.mat-mdc-form-field {
    width: 100%;
    margin-bottom: 10px;

    &.mat-form-field-invalid {
        .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-infix {
                    min-height: 50px;
                    width: 100%;

                    .mdc-floating-label {
                        mat-label {
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 100%;
                            color: var(--variable-color-danger);
                            opacity: 1;
                        }
                    }
                    .mat-mdc-input-element {
                        &::placeholder {
                            color: var(--variable-color-black) !important;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
        color: var(--variable-color-black) !important;
        max-width: 90%;
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        &::placeholder {
            color: var(--variable-color-black) !important;
            opacity: 0.5;
        }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background: var(--variable-color-light-white);
    }

    .mat-mdc-text-field-wrapper {
        border-radius: 5px;
        border: 1px solid transparent;
        height: 50px;
        padding: 0 10px;
        font-size: 15px;

        &:hover,
        &:focus,
        &:focus-within {
            border: 1px solid var(--variable-color-black);
            background: var(--variable-color-white);
        }

        .mdc-line-ripple,
        .mat-mdc-form-field-focus-overlay {
            display: none;
        }

        .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {
                width: 100%;
                min-height: 50px;
                position: relative;

                .color-block {
                    min-width: 24px;
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                }

                .arrow-down,
                .color-block {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .arrow-down {
                    display: flex;

                    &.suffix-trigger {
                        color: #0061fe;
                        cursor: pointer;
                    }
                }

                .mdc-floating-label {
                    top: 24px;

                    mat-label {
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 117%;
                        color: var(--variable-color-black);
                        opacity: 0.5;

                        &.red-dark {
                            color: red;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .mat-mdc-form-field-subscript-wrapper {
        .mat-mdc-form-field-error-wrapper {
            padding-left: 0;

            .mat-mdc-form-field-error {
                font-size: 12px;
                color: var(--variable-color-danger);
            }
        }
    }
}

.mdc-menu-surface.mat-mdc-autocomplete-panel.custom-drop-wrap {
    padding: 5px;
    min-height: auto;
    border-radius: 4px;
    box-shadow: var(--variable-color-drop-down-box-shadow);
    overflow: auto;
    background-color: var(--variable-background-color);

    &::-webkit-scrollbar {
        display: none;
    }
    .mat-mdc-option {
        height: 36px;
        line-height: 36px;
        min-height: 36px;
        border-radius: 4px;

        &.customClass {
            height: 50px;
            line-height: 50px;
            min-height: 50px;
            border-radius: 4px;
        }
        // padding: 0;

        .options-text {
            font-weight: 400;
            font-size: 15px;
            line-height: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
            height: 100%;
            .speaker {
                p {
                    width: 26px;
                    min-width: 26px;
                    height: 26px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    background: var(--variable-color-light-white);
                    align-items: center;
                }
            }

            &:hover {
                background: var(--variable-color-light-white);
            }
        }

        &.menu-container {
            border-top: 1px solid var(--variable-color-off-white);
            border-radius: 4px;
            max-height: 116px;
            height: auto;
            // padding: 0 21px;
            // margin: 5px -5px -5px;
            margin-top: 5px;

            &::before {
                content: '';
                // width: 100;
            }

            &:hover {
                background: var(--variable-color-light-white);
            }

            &.rm-border {
                border: none;
            }

            &.rm-hover {
                border-top: 1px solid transparent;

                &:hover {
                    background: transparent;
                }
            }
        }

        .mdc-list-item__primary-text {
            width: 100%;
            color: var(--variable-color-black);

            .options-container {
                font-weight: 400;
                font-size: 15px;
                line-height: 100%;
                display: flex;
                gap: 12px;
                align-items: center;
                position: relative;
                height: 36px;
                line-height: 36px;

                svg-icon {
                    display: flex;
                    svg {
                        path {
                            fill: var(--variable-color-black);
                        }
                    }
                }
            }

            .colorblock-container {
                display: flex;
                gap: 11.5px;
                flex-wrap: wrap;
                padding: 20px 0;

                .color-block {
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    cursor: pointer;
                    border-radius: 4px;
                    border: 1px solid transparent;

                    &:hover,
                    &.active {
                        border: 1px solid var(--variable-color-black);
                    }
                }
            }
        }

        &.mdc-list-item--selected {
            background: transparent;

            .mdc-list-item__primary-text {
                color: var(--variable-color-black);
            }
        }

        &:hover:not(.mdc-list-item--disabled) {
            background-color: var(--variable-color-light-white);
        }

        // &:hover {
        //     background: var(--variable-color-light-white) ;
        // }

        .mdc-list-item__primary-text {
            font-size: 14px;

            .d-block {
                span {
                    max-width: 100%;
                    display: block;
                    line-height: 36px;
                }
            }

            span {
                max-width: 95%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                height: 36px;
                line-height: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}

::ng-deep.attendee-drop-down {
    .mdc-menu-surface.mat-mdc-autocomplete-panel.custom-drop-wrap {
        height: 50px;
        line-height: 50px;
        min-height: 50px;
    }
}

/* Mat Switch css */

.mat-mdc-slide-toggle .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
.mat-mdc-slide-toggle .mdc-switch:enabled:active .mdc-switch__track::after {
    background: var(--variable-color-black) !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:active) .mdc-switch__track::after {
    background: var(--variable-color-black) !important;
}

.mat-mdc-slide-toggle {
    // &.cdk-focused {}

    .mdc-form-field {
        width: 45px;

        .mdc-switch {
            width: 45px;
            min-height: 22px;
            border-radius: 10px;
            display: flex;
            align-items: center;

            &.mdc-switch--unselected {
                &:hover {
                    .mdc-switch__track {
                        height: 22px;
                        border-radius: 20px;

                        &:after {
                            background: var(--material-switch-after-color);
                        }
                    }
                }

                .mdc-switch__track {
                    height: 22px;
                    border-radius: 20px;

                    &:after {
                        background: var(--material-switch-after-color);
                    }
                    &:before {
                        background: var(--material-switch-before-color);
                    }
                }

                // .mdc-switch__handle-track {}

                .mdc-switch__handle {
                    width: 16px;
                    height: 16px;
                    left: -7px;

                    .mdc-switch__ripple {
                        display: none;
                    }

                    .mdc-switch__icons {
                        svg {
                            width: 16px;
                            height: 16px;
                            background: var(--variable-color-white);
                            border-radius: 50%;

                            path {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.mdc-switch--selected {
                &:hover {
                    .mdc-switch__track {
                        height: 22px;
                        border-radius: 20px;

                        &:after {
                            background: var(--material-switch-after-color);
                        }
                    }
                }

                .mdc-switch__track {
                    height: 22px;
                    border-radius: 20px;

                    &:after {
                        background: var(--variable-color-black);
                    }
                }

                // .mdc-switch__handle-track {}

                .mdc-switch__handle {
                    width: 16px;
                    height: 16px;
                    .mdc-switch__ripple {
                        display: none;
                    }

                    .mdc-switch__icons {
                        svg {
                            width: 16px;
                            height: 16px;
                            background: var(--variable-color-white);
                            border-radius: 50%;

                            path {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .mdc-switch__handle-track {
            width: 16px;
            height: 16px;
            top: unset;
            left: 10px;

            .mat-slide-toggle-thumb {
                width: 16px;
                height: 16px;
                background: var(--variable-color-white);
            }
        }
    }
}

/* table css */

.mat-mdc-table.mdc-data-table__table.cdk-table.mat-elevation-z8 {
    box-shadow: none;
    // border: 1px solid var(--variable-color-off-white);
    // border-radius: 10px;
    background-color: unset;

    thead {
        tr {
            height: 50px;

            th {
                font-weight: 700;
                font-size: 12px;
                line-height: 150%;
                color: var(--variable-color-black);
                text-transform: uppercase;
                border-bottom-color: var(--variable-color-off-white);
                background-color: var(--variable-color-white);
            }
        }
    }

    tbody {
        tr {
            height: 50px;
            padding: 10px 20px;

            td {
                font-weight: 400;
                font-size: 15px;
                line-height: 150%;
                color: var(--variable-color-black);
                border-bottom-color: var(--variable-color-off-white);

                .add-speaker {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: 1px dashed var(--variable-color-off-white);
                    border-radius: 4px;

                    svg {
                        fill: var(--variable-color-black);
                    }
                }
            }
        }
    }
}

.mat-mdc-table.mdc-data-table__table.cdk-table.mat-elevation-z8.mat-elevation-table tbody tr td {
    overflow: unset;
    background-color: var(--variable-color-white);
    cursor: pointer;
}

/* radio group css */
.mat-mdc-radio-button {
    // &.cdk-focused {
    //     .mat-ripple {
    //         .mat-ripple-element {
    //             background: rgba(63, 166, 217, 0.5) !important;
    //         }
    //     }
    // }

    .mdc-radio {
        .mdc-radio__background {
            &::before {
                display: none;
            }
        }
    }

    &.mat-mdc-radio-checked {
        .mdc-form-field {
            .mdc-radio {
                padding: 0;

                .mdc-radio__outer-circle {
                    border-color: var(--variable-color-blue) !important;
                }

                .mdc-radio__inner-circle {
                    border-color: var(--variable-color-blue) !important;
                }
            }
        }
    }

    .mdc-form-field {
        label {
            padding-left: 18px;
            color: var(--variable-color-black);
        }

        .mdc-radio {
            padding: 0;
            .mdc-radio__outer-circle {
                border-color: #49454f !important;
            }

            .mat-ripple {
                .mat-ripple-element {
                    background: rgba(63, 166, 217, 0.5) !important;
                }
            }
        }
    }
}

.mat-mdc-tab-group {
    .mat-mdc-tab-header {
        .mat-mdc-tab-label-container {
            padding: 0 20px;

            .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                    border: 1px solid var(--variable-color-off-white);
                    border-radius: 4px;
                    margin-bottom: 30px;
                    overflow: hidden;

                    .mdc-tab.mat-mdc-tab {
                        background: var(--variable-color-white);
                        height: 38px;

                        .mdc-tab__ripple {
                            &::before {
                                background-color: var(--variable-color-light-white);
                            }
                        }

                        &.mdc-tab--active {
                            background: var(--variable-color-light-white);
                        }

                        .mdc-tab__content {
                            .mdc-tab__text-label {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 150%;
                                text-transform: uppercase;
                                color: var(--variable-color-black);
                            }
                        }

                        .mdc-tab-indicator {
                            &__content--underline {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--variable-color-black) !important;
    color: var(--variable-color-white);
    padding: 0px 7px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;

    .mdc-tooltip__surface {
        background: var(--variable-color-black) !important;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--variable-color-white) !important;
    }

    &:after {
        position: absolute;
        content: ' ';
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--variable-color-black);
    }
}

.custom-tooltip-right {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--variable-color-black) !important;
    color: var(--variable-color-white);
    padding: 0px 7px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    min-width: 75px;

    .mdc-tooltip__surface {
        background: var(--variable-color-black) !important;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--variable-color-white) !important;
    }

    &:after {
        display: inline-flex;
        position: absolute;
        content: ' ';
        top: 30%;
        left: -5px;
        transform: translateX(-50%);
        border-bottom: 5px solid transparent;
        border-right: 5px solid var(--variable-color-black);
        border-left: 5px solid transparent;
        border-top: 5px solid transparent;
    }
}

.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
    margin-left: 1px;
    margin-right: 0px;
    content: '*';
    color: var(--variable-color-danger);
    padding-left: 5px;
}

.animate__animated {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.workflow-modal-container {
    .mat-dialog-container {
        border-radius: 0;
    }
}

@keyframes slideInRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
}

.mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before {
    background: var(--material-switch-before-color) !important;
}

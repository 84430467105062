// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '~quill/dist/quill.snow.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import './common.scss';
@import './variable.scss';
@import './material.scss';
@import 'intl-tel-input/build/css/intlTelInput.css';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$webapp-primary: mat.define-palette(mat.$indigo-palette);
$webapp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$webapp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$webapp-theme: mat.define-light-theme(
    (
        color: (
            primary: $webapp-primary,
            accent: $webapp-accent,
            warn: $webapp-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($webapp-theme);

/* You can add global styles to this file, and also import other style files */
:root {
    --register-ticket-theme: #f5f5f5;
    --register-ticket-theme-remaining-bg: rgba(105, 190, 40, 0.1);
    --event-feedback-theme: #69be28;
    --skeleton-ui-background: #f5f5f5;
    --live-interactivity-theme: #f5f5f5;
    --af-button-color: #69be28;
    --af-background-color: #ffffff;
    --af-text-color: #000000;
    --af-modal-background-color: #f5f5f5;
    --af-button-text-color: #ffffff;
    --af-link-color: #69be28;
    --af-element-border-radius: 10px;
    --af-button-border-radius: 5px;
    --af-rounded-control-padding: 0;
    --af-rounded-down-arrow-padding: 0;
    --af-isknown--container-padding: 10px;
    --af-header-font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        sans-serif;
    --af-header-font-weight: 700;
    --af-overlay-text-color: #ffffff;
    --af--border-bottom-color: #d9d9d9;
    --email-btn-color: #007bc2;

    --variable-color-white: #fff;
    --variable-color-off-white: #dfdfdf;
    --variable-color-light-white: #f5f5f5;
    --variable-color-gray: rgb(216, 216, 216);
    --variable-background-color: #fff;

    --variable-color-off-black: #49454f;
    --variable-color-light-black: #808080; // fourth font
    --variable-color-black: #000000;

    --variable-color-green: #3fc063;
    --variable-color-light-green: #4cd964;
    --variable-color-off-grren: #3bb273;

    --variable-color-golden-yellow: #ee9500;
    --variable-color-orange: #ef9621;
    --variable-color-blue: #0061fe;
    --variable-color-danger: #f44336;
    --variable-color-error: #ff3b30;
    --variable-color-disable: #bfbfbf;
    --variable-color-drop-down-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    --variable-color-light-gray: #eef2f6;
    --variable-border-light-color: #e9edf1;

    --material-switch-after-color: #d9d9d9;
    --material-switch-before-color: #e0e0e0;
}

html,
body {
    height: 100%;
    box-sizing: border-box;
    font-size: 15px;
    background: var(--variable-background-color);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

*,
body {
    margin: 0;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
    // font-size: 15px;

    button {
        font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
            sans-serif !important;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
    body {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
}
